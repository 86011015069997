import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import "styles/dashboard.style.css"
//import {DivReturn,logtest,GetJSONfromStorage} from "components/medangle/HandleLocalStorage"
//import defaultDivReturn from "components/medangle/HandleLocalStorage"
import { compress, decompress } from 'compress-json'


function DashboardContent(props){
  function Test(){
    console.log("Before GENERAL_GLOBAL_SET_LOCALSTORAGE_ALL_IN_ONE")
    console.log("Inside GENERAL_GLOBAL_SET_LOCALSTORAGE_ALL_IN_ONE ")
    /*
   // //let object = require("../../assets/vqs_topics.json");
    //localStorage.setItem("vqs_topics", JSON.stringify(object));
    //console.log(localStorage.getItem("vqs_topics"))
var testObject = { 'one': 1, 'two': 2, 'three': 3 };
var Keys=  ["vqs_topics","renal","mcqs","mcqs_topic","cases","cases_topics","vqs"]

Keys.forEach(key => {
  console.log("working for key: "+key)
    if(localStorage.hasOwnProperty(key)){
        console.log("Global Key:"+key+" already in localstorage")
        //return JSON.parse(localStorage.getItem(NameofJSON))
    }
    else{
        var name = "../../assets/"+key+".json"
        let object = require(name);
        localStorage.setItem(key, JSON.stringify(object));
        console.log("GENERAL_GLOBAL_SET_LOCALSTORAGE_ALL_IN_ONE:\nKey: "+key+" SET")
    }

})
*/
//localStorage.clear();
let vqs = require("../../assets/vqs.json");
let vqs_topics = require("../../assets/vqs_topics.json");
let mcqs = require("../../assets/mcqs.json");
let mcqs_topic = require("../../assets/mcqs_topic.json");
let cases = require("../../assets/cases.json");
let cases_topics = require("../../assets/cases_topics.json");
let renal = require("../../assets/renal.json");

// Put the object into storage
localStorage.setItem('vqs', JSON.stringify(compress(vqs)));
localStorage.setItem('vqs_topics', JSON.stringify(compress(vqs_topics)));
localStorage.setItem('mcqs', JSON.stringify(compress(mcqs)));
localStorage.setItem('mcqs_topic', JSON.stringify(compress(mcqs_topic)));
localStorage.setItem('cases', JSON.stringify(compress(cases)));
localStorage.setItem('cases_topics', JSON.stringify(compress(cases_topics)));
localStorage.setItem('renal', JSON.stringify(compress(renal)));
    console.log("After GENERAL_GLOBAL_SET_LOCALSTORAGE_ALL_IN_ONE")
  }
return( <>
   <div
    className="columns is-centered dashboard-padding-md"
    style={{ boxSizing: "border-box" }}
  >
    <div className="column is-3-widescreeen is-3-desktop is-4-tablet">
      <div>
        <a
          data-savepage-href="/users/edit"
          href="https://medangle.com/users/edit"
        >
          Upload profile picture
        </a>
        <p className="is-size-5-desktop is-size-5-tablet global-text has-text-weight-bold is-size-4-touch">
          Muneeb Akhter
          <span className="icon is-size-5 medangle-green-cmyk">
            <i className="fas fa-check-circle" />
          </span>
        </p>
        <p className="global-text">Dow Medical College</p>
      </div>
      <hr />
     
      <Link
        data-savepage-href="/muneebakhtar01lsggb/performance"
        to="/muneebakhtar01lsggb/dashboard"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/performance-aeea2c21d61231266a53b4953303d028725023bf304052d2d3004bad6a4f7d3b.svg"
            data-savepage-src="/assets/svg/performance-aeea2c21d61231266a53b4953303d028725023bf304052d2d3004bad6a4f7d3b.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5MC4zIDg5LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDkwLjMgODkuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8dGl0bGU+cGVyZm9ybWFuY2UyPC90aXRsZT4KPHBhdGggZD0iTTg4LjUsMjZjLTEsMC0xLjksMC44LTEuOSwxLjl2NTUuNkg3Ni4zVjQwLjZjMC0xLTAuOC0xLjktMS45LTEuOWMtMSwwLTEuOSwwLjgtMS45LDEuOXY0Mi45SDYyLjJ2LTIzYzAtMS0wLjgtMS45LTEuOS0xLjkKCWMtMSwwLTEuOSwwLjgtMS45LDEuOWMwLDAsMCwwLDAsMHYyM0g0OC4xVjY5LjdjMC0xLTAuOC0xLjktMS45LTEuOWMtMSwwLTEuOSwwLjgtMS45LDEuOWMwLDAsMCwwLDAsMHYxMy45SDM0VjY2LjQKCWMwLTEtMC44LTEuOS0xLjktMS45Yy0xLDAtMS45LDAuOC0xLjksMS45djE3LjFIMjB2LTEuOGMwLTEtMC44LTEuOS0xLjktMS45Yy0xLDAtMS45LDAuOC0xLjksMS45bDAsMHYxLjhoLThsMjUtMzYuN2wxNC42LDkuMQoJYzEuMywwLjgsMi45LDAuNCwzLjgtMC44bDMxLjUtNDVsMS4zLDYuNmMwLjMsMS4zLDEuNCwyLjMsMi44LDIuM2MwLjIsMCwwLjQsMCwwLjUsMGMxLjUtMC4zLDIuNS0xLjgsMi4yLTMuM2MwLDAsMCwwLDAsMGwtMi41LTEzCgljLTAuMy0xLjUtMS44LTIuNS0zLjMtMi4ybC0xMywyLjVjLTEuNSwwLjMtMi41LDEuOC0yLjIsMy4zczEuOCwyLjUsMy4zLDIuMmw1LjktMS4xTDQ4LjYsNDkuN0wzNCw0MC42Yy0xLjMtMC44LTMtMC41LTMuOCwwLjgKCUw1LjcsNzcuMlYzLjFjMC0xLjYtMS4zLTIuOC0yLjgtMi44UzAsMS41LDAsMy4xdjgzLjJsMCwwYzAsMCwwLDAuMSwwLDAuMWMwLDAuMSwwLDAuMywwLDAuNGMwLDAuMSwwLDAuMiwwLjEsMC4yCgljMCwwLjEsMC4xLDAuMiwwLjEsMC4zYzAsMC4xLDAuMSwwLjIsMC4xLDAuMnMwLjEsMC4yLDAuMSwwLjJzMC4xLDAuMSwwLjIsMC4yczAuMSwwLjIsMC4yLDAuMmMwLjEsMC4xLDAuMiwwLjIsMC4zLDAuMmwwLjEsMC4xCglsMCwwbDAsMGMwLjEsMC4xLDAuMywwLjIsMC40LDAuMmwwLjEsMC4xTDIuMSw4OWgwLjJjMC4yLDAsMC4zLDAsMC41LDAuMWg4NC41YzEsMCwyLTAuNiwyLjQtMS40YzAuNC0wLjQsMC42LTAuOSwwLjYtMS40VjI3LjkKCUM5MC40LDI2LjksODkuNSwyNiw4OC41LDI2Qzg4LjUsMjYsODguNSwyNiw4OC41LDI2eiIvPgo8L3N2Zz4K"
          />
          <span className="padding-xs">Dashboard -&gt;</span>
        </p>
      </Link>
      <a
        data-savepage-href="/muneebakhtar01lsggb/performance"
        href="https://medangle.com/muneebakhtar01lsggb/performance"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/performance-aeea2c21d61231266a53b4953303d028725023bf304052d2d3004bad6a4f7d3b.svg"
            data-savepage-src="/assets/svg/performance-aeea2c21d61231266a53b4953303d028725023bf304052d2d3004bad6a4f7d3b.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5MC4zIDg5LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDkwLjMgODkuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8dGl0bGU+cGVyZm9ybWFuY2UyPC90aXRsZT4KPHBhdGggZD0iTTg4LjUsMjZjLTEsMC0xLjksMC44LTEuOSwxLjl2NTUuNkg3Ni4zVjQwLjZjMC0xLTAuOC0xLjktMS45LTEuOWMtMSwwLTEuOSwwLjgtMS45LDEuOXY0Mi45SDYyLjJ2LTIzYzAtMS0wLjgtMS45LTEuOS0xLjkKCWMtMSwwLTEuOSwwLjgtMS45LDEuOWMwLDAsMCwwLDAsMHYyM0g0OC4xVjY5LjdjMC0xLTAuOC0xLjktMS45LTEuOWMtMSwwLTEuOSwwLjgtMS45LDEuOWMwLDAsMCwwLDAsMHYxMy45SDM0VjY2LjQKCWMwLTEtMC44LTEuOS0xLjktMS45Yy0xLDAtMS45LDAuOC0xLjksMS45djE3LjFIMjB2LTEuOGMwLTEtMC44LTEuOS0xLjktMS45Yy0xLDAtMS45LDAuOC0xLjksMS45bDAsMHYxLjhoLThsMjUtMzYuN2wxNC42LDkuMQoJYzEuMywwLjgsMi45LDAuNCwzLjgtMC44bDMxLjUtNDVsMS4zLDYuNmMwLjMsMS4zLDEuNCwyLjMsMi44LDIuM2MwLjIsMCwwLjQsMCwwLjUsMGMxLjUtMC4zLDIuNS0xLjgsMi4yLTMuM2MwLDAsMCwwLDAsMGwtMi41LTEzCgljLTAuMy0xLjUtMS44LTIuNS0zLjMtMi4ybC0xMywyLjVjLTEuNSwwLjMtMi41LDEuOC0yLjIsMy4zczEuOCwyLjUsMy4zLDIuMmw1LjktMS4xTDQ4LjYsNDkuN0wzNCw0MC42Yy0xLjMtMC44LTMtMC41LTMuOCwwLjgKCUw1LjcsNzcuMlYzLjFjMC0xLjYtMS4zLTIuOC0yLjgtMi44UzAsMS41LDAsMy4xdjgzLjJsMCwwYzAsMCwwLDAuMSwwLDAuMWMwLDAuMSwwLDAuMywwLDAuNGMwLDAuMSwwLDAuMiwwLjEsMC4yCgljMCwwLjEsMC4xLDAuMiwwLjEsMC4zYzAsMC4xLDAuMSwwLjIsMC4xLDAuMnMwLjEsMC4yLDAuMSwwLjJzMC4xLDAuMSwwLjIsMC4yczAuMSwwLjIsMC4yLDAuMmMwLjEsMC4xLDAuMiwwLjIsMC4zLDAuMmwwLjEsMC4xCglsMCwwbDAsMGMwLjEsMC4xLDAuMywwLjIsMC40LDAuMmwwLjEsMC4xTDIuMSw4OWgwLjJjMC4yLDAsMC4zLDAsMC41LDAuMWg4NC41YzEsMCwyLTAuNiwyLjQtMS40YzAuNC0wLjQsMC42LTAuOSwwLjYtMS40VjI3LjkKCUM5MC40LDI2LjksODkuNSwyNiw4OC41LDI2Qzg4LjUsMjYsODguNSwyNiw4OC41LDI2eiIvPgo8L3N2Zz4K"
          />
          <span className="padding-xs">My Performance -&gt;</span>
        </p>
      </a>
      <a
        data-savepage-href="/muneebakhtar01lsggb/saved"
        href="https://medangle.com/muneebakhtar01lsggb/saved"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/saved-questions-51d2e5d2950231fab09889ef73be7f39cb0e0620a6658576dc1270b84911d56d.svg"
            data-savepage-src="/assets/svg/saved-questions-51d2e5d2950231fab09889ef73be7f39cb0e0620a6658576dc1270b84911d56d.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OCA0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggNDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+Cjx0aXRsZSAgaWQ9ImJvb2ttYXJrSWNvblRpdGxlIj5Cb29rbWFyazwvdGl0bGU+CjxkZXNjICBpZD0iYm9va21hcmtJY29uRGVzYyI+SWNvbiBvZiBhIHJpYmJvbiBib29rbWFyazwvZGVzYz4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTM0LDEyLjl2MjUuOWwtMTAtNi40bC0xMCw2LjRWMTIuOWMwLTIuOSwyLjItNS4zLDUtNS4zaDEwQzMxLjgsNy42LDM0LDEwLDM0LDEyLjl6Ii8+Cjwvc3ZnPgo="
          />
          <span className="padding-xs">My Saved Questions -&gt;</span>
        </p>
      </a>
      <a data-savepage-href="/testmode" href="https://medangle.com/testmode">
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/test-mode-fe38e3a04ffa65d6a9055b4055ebf9dc2b13b264446d6f00d51b19001b4babc0.svg"
            data-savepage-src="/assets/svg/test-mode-fe38e3a04ffa65d6a9055b4055ebf9dc2b13b264446d6f00d51b19001b4babc0.svg"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnRlc3QtbW9kZTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjMuNSIgeT0iMy41IiB3aWR0aD0iMTIxIiBoZWlnaHQ9IjEyMSIvPjxwYXRoIGQ9Ik0xMjEsN1YxMjFIN1Y3SDEyMW03LTdIMFYxMjhIMTI4VjBaIi8+PHBhdGggZD0iTTE5Ljc4LDg3LjI3LDM1LjE0LDQwLjczSDUyLjRMNjcuNzYsODcuMjdINTQuMTNsLTIuNjgtOC45MUgzNi4wOWwtMi42OCw4LjkxWk00OC41OSw2OC45MSw0NCw1My41NWgtLjM2TDM5LDY4LjkxWiIvPjxwYXRoIGQ9Ik04Ni4zMiw2OS4xOEg3NC42VjU5SDg2LjMyVjQ3LjI4SDk2LjVWNTloMTEuNzJWNjkuMThIOTYuNVY4MC45SDg2LjMyWiIvPjwvZz48L2c+PC9zdmc+"
          />
          <span className="padding-xs">Test Mode -&gt;</span>
        </p>
      </a>
      <Link
        data-savepage-href="/muneebakhtar01lsggb/dashboard/smart-sets"
        to="/smart-sets"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/smart-sets-213aab9e34c6d966fc48fcc2153ec7905f9122b2617447c3c13da84f0080e92c.svg"
            data-savepage-src="/assets/svg/smart-sets-213aab9e34c6d966fc48fcc2153ec7905f9122b2617447c3c13da84f0080e92c.svg"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2OCA0NiI+PHRpdGxlPnNtYXJ0LXNldHM8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggZD0iTTM4LDE2VjJhMiwyLDAsMCwxLDItMkg2NmEyLDIsMCwwLDEsMiwyVjM0YTIsMiwwLDAsMS0yLDJINTN2OGEyLDIsMCwwLDEtMiwySDE2YTIsMiwwLDAsMS0yLTJWMzBIMmEyLDIsMCwwLDEtMi0yVjJBMiwyLDAsMCwxLDIsMEgyOGEyLDIsMCwwLDEsMiwyVjE2Wk02NCwzMlY0SDQyVjE2aDlhMiwyLDAsMCwxLDIsMlYzMlpNMjYsMTZWNEg0VjI2SDE0VjE4YTIsMiwwLDAsMSwyLTJabTQsNHY4YTIsMiwwLDAsMS0yLDJIMThWNDJINDlWMzZINDBhMiwyLDAsMCwxLTItMlYyMFpNMTgsMjB2Nmg4VjIwWm0zMSwwSDQyVjMyaDdaIi8+PC9nPjwvZz48L3N2Zz4="
          />
          <span className="padding-xs">Smart Sets -&gt;</span>
          <span className="new-feature" id="biology-gradient">
            New
          </span>
        </p>
      </Link>
      <Link
        data-savepage-href="/muneebakhtar01lsggb/dashboard/mcqs"
        to="/mcqs"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/mcqs-a5780d5de86efc833e5b76ea1008e9f24023f8c9e4099ac0c8ec336f1ebb1504.svg"
            data-savepage-src="/assets/svg/mcqs-a5780d5de86efc833e5b76ea1008e9f24023f8c9e4099ac0c8ec336f1ebb1504.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OCA0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggNDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cgkuc3Qxe3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cgkuc3Qye3N0cm9rZTojMDAwMDAwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHRpdGxlICBpZD0iZGFzaGJvYXJkSWNvblRpdGxlIj5EYXNoYm9hcmQ8L3RpdGxlPgo8ZGVzYyAgaWQ9ImRhc2hib2FyZEljb25EZXNjIj5JY29uIG9mIGEgZGFzaGJvYXJkPC9kZXNjPgo8cmVjdCB4PSI0IiB5PSI0IiBjbGFzcz0ic3QwIiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiLz4KPGc+Cgk8Zz4KCQk8bGluZSBjbGFzcz0ic3QxIiB4MT0iMjEuOSIgeTE9IjE0IiB4Mj0iMzMuOSIgeTI9IjE0Ii8+CgkJPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjEiIHkxPSIxNCIgeDI9IjE0LjEiIHkyPSIxNCIvPgoJCTxjaXJjbGUgY2xhc3M9InN0MiIgY3g9IjE0LjEiIGN5PSIxNCIgcj0iMiIvPgoJPC9nPgoJPGc+CgkJPGxpbmUgY2xhc3M9InN0MSIgeDE9IjIxLjkiIHkxPSIyNCIgeDI9IjMzLjkiIHkyPSIyNCIvPgoJCTxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4xIiB5MT0iMjQiIHgyPSIxNC4xIiB5Mj0iMjQiLz4KCQk8Y2lyY2xlIGNsYXNzPSJzdDIiIGN4PSIxNC4xIiBjeT0iMjQiIHI9IjIiLz4KCTwvZz4KCTxnPgoJCTxsaW5lIGNsYXNzPSJzdDEiIHgxPSIyMS45IiB5MT0iMzQiIHgyPSIzMy45IiB5Mj0iMzQiLz4KCQk8bGluZSBjbGFzcz0ic3QxIiB4MT0iMTQuMSIgeTE9IjM0IiB4Mj0iMTQuMSIgeTI9IjM0Ii8+CgkJPGNpcmNsZSBjbGFzcz0ic3QyIiBjeD0iMTQuMSIgY3k9IjM0IiByPSIyIi8+Cgk8L2c+CjwvZz4KPC9zdmc+Cg=="
          />
          <span className="padding-xs">Multiple Choice Questions -&gt;</span>
        </p>
      </Link>
      <Link
        data-savepage-href="/muneebakhtar01lsggb/dashboard/vqs"
        to="/vqs"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/vqs-eb4ef6ff53bc8f28e428dbf4117b6b921744603e989cfde788a89b70d146510d.svg"
            data-savepage-src="/assets/svg/vqs-eb4ef6ff53bc8f28e428dbf4117b6b921744603e989cfde788a89b70d146510d.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OCA0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggNDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+Cjx0aXRsZSAgaWQ9ImNoYXRJY29uVGl0bGUiPkNoYXQ8L3RpdGxlPgo8ZGVzYyAgaWQ9ImNoYXRJY29uRGVzYyI+SWNvbiBvZiBhIGNoYXQgYnViYmxlPC9kZXNjPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTcuNiwzNi40TDgsNDEuM2wxLjMtOS4xQzYsMjkuMyw0LDI1LjQsNCwyMS4zYzAtOC44LDktMTYsMjAtMTZzMjAsNy4yLDIwLDE2cy05LDE2LTIwLDE2CglDMjEuOCwzNy4zLDE5LjYsMzcsMTcuNiwzNi40eiIvPgo8L3N2Zz4K"
          />
          <span className="padding-xs">Verbal Questions -&gt;</span>
        </p>
      </Link>
      <Link
        data-savepage-href="/muneebakhtar01lsggb/dashboard/cases"
        to="/cases"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/clinical-cases-7901ae31560fe11b40a8140841b997b0ac75c0e0debfacaf34b491088429282f.svg"
            data-savepage-src="/assets/svg/clinical-cases-7901ae31560fe11b40a8140841b997b0ac75c0e0debfacaf34b491088429282f.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OCA0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggNDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDo0O3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+Cjx0aXRsZSAgaWQ9ImNsaXBib2FyZEljb25UaXRsZSI+Q2xpcGJvYXJkPC90aXRsZT4KPGRlc2MgIGlkPSJjbGlwYm9hcmRJY29uRGVzYyI+SWNvbiBvZiBhIHJlY3Rhbmd1bGFyIGJvYXJkIHdpdGggYSBjbGlwPC9kZXNjPgo8Zz4KCTxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjMwLDcgMzgsNyAzOCw0MyAxMCw0MyAxMCw3IDEwLDcgMTgsNyAJIi8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjgsOWgtOGMtMS4xLDAtMi0wLjktMi0yczAuOS0yLDItMmg4YzEuMSwwLDIsMC45LDIsMlMyOS4xLDksMjgsOXoiLz4KPC9nPgo8L3N2Zz4K"
          />
          <span className="padding-xs">Clinical Cases -&gt;</span>
        </p>
      </Link>
      <hr />
      <a
        data-savepage-href="/practice-quizzes"
        href="https://medangle.com/practice-quizzes"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/grid-5d4b16e901ad31fa9253f7150a7a155a207a4d425629ae764f1cb5c787e03304.svg"
            data-savepage-src="/assets/svg/grid-5d4b16e901ad31fa9253f7150a7a155a207a4d425629ae764f1cb5c787e03304.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMiAzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsNGg5LjN2OS4zSDRWNHogTTE4LjcsNEgyOHY5LjNoLTkuM1Y0eiBNMTguNywxOC43SDI4VjI4aC05LjNWMTguN3ogTTQsMTguN2g5LjNWMjhINFYxOC43eiIvPgo8L2c+Cjwvc3ZnPgo="
          />
          <span className="padding-xs">Free Quizzes -&gt;</span>
        </p>
      </a>
      <Link
        
        to="/usmle/tree" 
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/grid-5d4b16e901ad31fa9253f7150a7a155a207a4d425629ae764f1cb5c787e03304.svg"
            data-savepage-src="/assets/svg/grid-5d4b16e901ad31fa9253f7150a7a155a207a4d425629ae764f1cb5c787e03304.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMiAzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsNGg5LjN2OS4zSDRWNHogTTE4LjcsNEgyOHY5LjNoLTkuM1Y0eiBNMTguNywxOC43SDI4VjI4aC05LjNWMTguN3ogTTQsMTguN2g5LjNWMjhINFYxOC43eiIvPgo8L2c+Cjwvc3ZnPgo="
          />
          <span className="padding-xs">USMLE -&gt;</span>
        </p>
      </Link>
      <a
        data-savepage-href="/muneebakhtar01lsggb/dashboard/mcqs"
        href="https://medangle.com/muneebakhtar01lsggb/dashboard/mcqs"
      >
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/video-bb8028c20be35d1970a89c420f84839dedace689fac3476a8287a0199ae51aec.svg"
            data-savepage-src="/assets/svg/video-bb8028c20be35d1970a89c420f84839dedace689fac3476a8287a0199ae51aec.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OCA0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggNDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+Cjx0aXRsZSAgaWQ9InZpZGVvSWNvblRpdGxlIj5WaWRlbzwvdGl0bGU+CjxkZXNjICBpZD0idmlkZW9JY29uRGVzYyI+SWNvbiBvZiBhIHBsYXkgaWNvbiBjbG9zZWQgaW4gYSBjaXJjbGU8L2Rlc2M+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zNiwyNGwtMTgsOS44VjE0TDM2LDI0eiBNMjQsNGMxMSwwLDIwLDksMjAsMjBzLTksMjAtMjAsMjBTNCwzNSw0LDI0UzEzLDQsMjQsNHoiLz4KPC9zdmc+Cg=="
          />
          <span className="padding-xs">
            Videos <span className="tag is-light">TBA</span>
          </span>
        </p>
      </a>
      <a data-savepage-href="/contact" href="https://medangle.com/contact">
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/contact-medangle-ea895d33c55f1efe3e96d3dd36511860550534206ac14c9bc353b338fbdeb2c2.svg"
            data-savepage-src="/assets/svg/contact-medangle-ea895d33c55f1efe3e96d3dd36511860550534206ac14c9bc353b338fbdeb2c2.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OCA0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDggNDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cgkuc3Qxe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDt9Cjwvc3R5bGU+Cjx0aXRsZSAgaWQ9ImVudmVsb3BlQWx0SWNvblRpdGxlIj5FbnZlbG9wZTwvdGl0bGU+CjxkZXNjICBpZD0iZW52ZWxvcGVBbHRJY29uRGVzYyI+SWNvbiBvZiBhbiBwYXBlciBlbnZlbG9wZTwvZGVzYz4KPHJlY3QgeD0iNCIgeT0iMTAiIGNsYXNzPSJzdDAiIHdpZHRoPSI0MCIgaGVpZ2h0PSIyOCIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNNCwxMGwyMCwxOGwyMC0xOCIvPgo8L3N2Zz4K"
          />
          <span className="padding-xs">Support -&gt;</span>
        </p>
      </a>
      <a data-savepage-href="/settings" href="https://medangle.com/settings">
        <p className="has-text-weight-bold user-sidebar-link has-text-left">
          <img
            width={32}
            style={{ verticalAlign: "middle" }}
            data-savepage-currentsrc="https://medangle.com/assets/svg/settings-34bc33a2a76276e7d73bfbe4ab25bd81655cde4543a40cc94f4fadf77d425ba5.svg"
            data-savepage-src="/assets/svg/settings-34bc33a2a76276e7d73bfbe4ab25bd81655cde4543a40cc94f4fadf77d425ba5.svg"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMiAzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNiwxMmMyLjIsMCw0LDEuOCw0LDRzLTEuOCw0LTQsNHMtNC0xLjgtNC00UzEzLjgsMTIsMTYsMTJ6IE0yNS45LDIwYy0wLjQsMC44LTAuMiwxLjgsMC40LDIuNGwwLjEsMC4xCgljMSwxLDEsMi43LDAsMy44YzAsMCwwLDAsMCwwYy0xLDEtMi43LDEtMy44LDBjMCwwLDAsMCwwLDBsLTAuMS0wLjFjLTAuNi0wLjYtMS42LTAuOC0yLjQtMC40Yy0wLjgsMC4zLTEuMywxLjEtMS4zLDJWMjgKCWMwLDEuNS0xLjIsMi43LTIuNywyLjdzLTIuNy0xLjItMi43LTIuN3YtMC4xYzAtMC45LTAuNi0xLjctMS40LTJjLTAuOC0wLjQtMS44LTAuMi0yLjQsMC40bC0wLjEsMC4xYy0xLDEtMi43LDEtMy44LDBjMCwwLDAsMCwwLDAKCWMtMS0xLTEtMi43LDAtMy44YzAsMCwwLDAsMCwwbDAuMS0wLjFjMC42LTAuNiwwLjgtMS42LDAuNC0yLjRjLTAuMy0wLjgtMS4xLTEuMy0yLTEuM0g0Yy0xLjUsMC0yLjctMS4yLTIuNy0yLjdzMS4yLTIuNywyLjctMi43CgloMC4xYzAuOSwwLDEuNy0wLjYsMi0xLjRjMC40LTAuOCwwLjItMS44LTAuNC0yLjRMNS42LDkuNWMtMS0xLTEtMi43LDAtMy44YzAsMCwwLDAsMCwwYzEtMSwyLjctMSwzLjgsMGMwLDAsMCwwLDAsMGwwLjEsMC4xCgljMC42LDAuNiwxLjYsMC44LDIuNCwwLjRIMTJjMC44LTAuMywxLjMtMS4xLDEuMy0yVjRjMC0xLjUsMS4yLTIuNywyLjctMi43czIuNywxLjIsMi43LDIuN3YwLjFjMCwwLjksMC41LDEuNywxLjMsMgoJYzAuOCwwLjQsMS44LDAuMiwyLjQtMC40bDAuMS0wLjFjMS0xLDIuNy0xLDMuOCwwYzAsMCwwLDAsMCwwYzEsMSwxLDIuNywwLDMuOGMwLDAsMCwwLDAsMGwtMC4xLDAuMWMtMC42LDAuNi0wLjgsMS42LTAuNCwyLjRWMTIKCWMwLjMsMC44LDEuMSwxLjMsMiwxLjNIMjhjMS41LDAsMi43LDEuMiwyLjcsMi43cy0xLjIsMi43LTIuNywyLjdoLTAuMUMyNywxOC43LDI2LjIsMTkuMiwyNS45LDIweiIvPgo8L3N2Zz4K"
          />
          <span className="padding-xs">Settings -&gt;</span>
        </p>
      </a>
      <hr />
      <p className="global-text">
        Premium since:
        <br />
        <span className="fullwidth-information-button">
          <a
            data-savepage-href="/muneebakhtar01lsggb/subscriptions"
            href="https://medangle.com/muneebakhtar01lsggb/subscriptions"
          >
            Wednesday, Jul 28 - 14 days ago -&gt;
          </a>
        </span>
        <a
          data-savepage-href="/muneebakhtar01lsggb/subscriptions"
          href="https://medangle.com/muneebakhtar01lsggb/subscriptions"
        ></a>
      </p>
      <br />
      <p className="global-text">
        Your subscription ends on:
        <br />
        <span className="fullwidth-information-button">
          <a
            data-savepage-href="/muneebakhtar01lsggb/subscriptions"
            href="https://medangle.com/muneebakhtar01lsggb/subscriptions"
          >
            Never My Friend Muneeb -&gt;
          </a>
        </span>
        <a
          data-savepage-href="/muneebakhtar01lsggb/subscriptions"
          href="https://medangle.com/muneebakhtar01lsggb/subscriptions"
        ></a>
      </p>
      <br />
      <p className="global-text">
        Account standing:
        <br />
      </p>
      <span className="fullwidth-information-button">
        <span className="has-text-success">Behtreen</span>
      </span>
    </div>
    <div className="column is-6-widescreen is-6-desktop is-5-tablet dashboard-center">
      <div className="chartjs-size-monitor">
        <div className="chartjs-size-monitor-expand">
          <div className />
        </div>
        <div className="chartjs-size-monitor-shrink">
          <div className />
        </div>
      </div>
      <span
        id="dashtime-dashboard"
        className="is-size-4-widescreen is-size-3-desktop is-size-4-mobile has-text-weight-bold global-text"
      >
        Good night, Muneeb.
      </span>
      <br />
      <br />
      <p className="global-text">Your account is 101% complete.</p>
      <progress
        className="progress is-large is-success"
        value={100}
        max={100}
        style={{ borderRadius: 0 }}
      />
      <div>
        <p className="is-size-5 global-text">
          <i className="fas fa-check-circle medangle-green-cmyk" /> You verified
          your status as a medical student.
        </p>
      </div>
      <div>
        <p className="is-size-5 global-text">
          <i className="fas fa-times-circle medangle-red" /> Add your phone
          number.
          <a
            data-savepage-href="/settings"
            href="https://medangle.com/settings"
          >
            Add number -&gt;
          </a>
        </p>
      </div>
      <div>
        <p className="is-size-5 global-text">
          <i className="fas fa-check-circle medangle-green-cmyk" /> You added
          your school to your profile.
        </p>
      </div>
      <div>
        <p className="is-size-5 global-text">
          <i className="fas fa-check-circle medangle-green-cmyk" /> You added
          your graduation year to your profile.
        </p>
      </div>
      <div>
        <p className="is-size-5 global-text">
          <i className="fas fa-check-circle medangle-green-cmyk" /> You
          attempted a practice quiz
        </p>
      </div>
      <br />
      <br />
      <div className="onesignal-customlink-container" />
      <br />
      <br />
      <p className="is-size-5 global-text has-text-weight-bold">
        <img
          width={32}
          style={{ verticalAlign: "middle" }}
          data-savepage-currentsrc="https://medangle.com/assets/svg/line-chart-8791ae2d14d43b9387742f222e53b1c79fa50f60f69ba14ef21f6c98b9556f7a.svg"
          data-savepage-src="/assets/svg/line-chart-8791ae2d14d43b9387742f222e53b1c79fa50f60f69ba14ef21f6c98b9556f7a.svg"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLWxpbmVqb2luPSJhcmNzIj48cGF0aCBkPSJNMyAzdjE4aDE4Ii8+PHBhdGggZD0iTTE4LjcgOGwtNS4xIDUuMi0yLjgtMi43TDcgMTQuMyIvPjwvc3ZnPg=="
        />{" "}
        My Activity
      </p>
      <canvas
        id="user_dashboard_activity"
        height={325}
        style={{
          display: "block",
          width: 651,
          height: 325,
          /*savepageCanvasImage*/ backgroundImage: "url(data:image/png",
          backgroundAttachment: "scroll !important",
          backgroundBlendMode: "normal !important",
          backgroundClip: "content-box !important",
          backgroundColor: "transparent !important",
          backgroundOrigin: "content-box !important",
          backgroundPosition: "center center !important",
          backgroundRepeat: "no-repeat !important",
          backgroundSize: "100% 100% !important"
        }}
        width={651}
        className="chartjs-render-monitor"
      />
    </div>
    <div className="column is-3-widescreeen is-3-desktop is-3-tablet dashboard-right-sidebar">
      <div className="container-notif">
        <a
          data-savepage-href="notifications"
          href="https://medangle.com/muneebakhtar01lsggb/notifications"
          className="btn"
        >
          1 Notification
          <div className="btn-badge">
            <i className="fas fa-bell" />
          </div>
        </a>
      </div>
      <hr />
      <p className="has-text-weight-bold global-text">Keep going.</p>
      <p className="is-size-6 has-text-weight-bold global-text vertical-padding-up-down-xs">
        Continue foundation anatomy MCQs
        <br />
        <a
          className="button medangle-blue-bg has-text-white"
          data-savepage-href="/mcqs/tagged/semester1,foundation,anatomy?page=22&tracker_id=quSU0euSf6dx4tngUPdkuw-1582630849"
          href="https://medangle.com/mcqs/tagged/semester1,foundation,anatomy?page=22&tracker_id=quSU0euSf6dx4tngUPdkuw-1582630849"
        >
          <span>Resume from question # 22</span>
          <i
            className="fas fa-arrow-right global-text"
            style={{ paddingLeft: "0.5em" }}
          />
        </a>{" "}
        <br />
        <span className="is-size-7 has-text-weight-normal has-text-grey">
          <i className="far fa-clock" />
          Your last activity was over 1 year ago
        </span>
      </p>
    </div>
  </div>
  </>);
}

export default DashboardContent